import 'jquery';

import { GlobalToastrConfig, Toast, ToastrModule } from 'ngx-toastr';

import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { App } from './app.component';
import { routing } from './app.routing';
import { AppState, InternalStateType } from './app.service';
import { AuthModule } from './core/auth.module';
import { AuthGuard } from './core/guards/auth.guard';
import { CORE_PIPES } from './core/pipes';
import { CORE_SERVICES } from './core/services';
import { GlobalState } from './global.state';
import { PagesModule } from './pages/pages.module';
import { NgaModule } from './theme/nga.module';

// Application wide providers
const APP_PROVIDERS = [AppState, GlobalState, ...CORE_SERVICES, ...CORE_PIPES];

export type StoreType = {
  state: InternalStateType;
  restoreInputValues: () => void;
  disposeOldHosts: () => void;
};

const iconClasses = {
  error: 'toast-error',
  info: 'toast-info',
  success: 'toast-success',
  warning: 'toast-warning',
};

const toastrOptions: GlobalToastrConfig = {
  newestOnTop: true,
  maxOpened: 1,
  autoDismiss: true,
  iconClasses: iconClasses,
  preventDuplicates: true,
  disableTimeOut: false,
  timeOut: 8000,
  closeButton: true,
  extendedTimeOut: 1000,
  progressBar: false,
  enableHtml: true,
  toastClass: '',
  positionClass: 'toast-top-center',
  titleClass: '',
  messageClass: '',
  easing: 'fade',
  easeTime: 50,
  tapToDismiss: true,
  toastComponent: Toast,
  onActivateTick: false,
  countDuplicates: true,
  progressAnimation: 'decreasing',
  resetTimeoutOnDuplicate: false,
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [App],
  declarations: [App],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgaModule.forRoot(),
    ToastrModule.forRoot(toastrOptions),
    AuthModule,
    PagesModule,
    routing,
  ],
  providers: [APP_PROVIDERS, AuthGuard],
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public appState: AppState) { }

  hmrOnInit(store: StoreType) {
    if (!store || !store.state) return;
    console.log('HMR store', JSON.stringify(store, null, 2));
    // set state
    this.appState._state = store.state;
    // set input values
    if ('restoreInputValues' in store) {
      let restoreInputValues = store.restoreInputValues;
      setTimeout(restoreInputValues);
    }
    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }

  hmrOnDestroy(store: StoreType) {
    const cmpLocation = this.appRef.components.map((cmp) => cmp.location.nativeElement);
    // save state
    const state = this.appState._state;
    store.state = state;
  }

  hmrAfterDestroy(store: StoreType) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
