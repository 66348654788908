import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class RoleAccessService {
  allAccess = ['/pages/dashboard'];
  allVisibility = ['pages', 'dashboard', 'logout'];

  constructor(private authService: AuthService) {}

  canAccessRoute(route: string): boolean {
    if (this.authService.role === 'admin') {
      return true;
    }

    if (this.allAccess.indexOf(route) > -1) {
      return true;
    }

    let found: boolean = false;

    this.authService.claims.forEach((claim) => {
      if (route && route.endsWith(claim)) {
        found = true;
      }
    });

    return found;
  }

  isPageVisible(route: string): boolean {
    if (this.authService.role === 'admin') {
      return true;
    }
    if (this.allVisibility.indexOf(route) > -1) {
      return true;
    }

    let topLevelOptions = [];

    this.authService.claims.forEach((claim) => {
      const claimsSplit = claim.split('/');

      if (claimsSplit) {
        if (topLevelOptions.indexOf(claimsSplit[0]) < 0) {
          topLevelOptions.push(claimsSplit[0]);
        }
      }
    });

    if (topLevelOptions.indexOf(route) > -1) {
      return true;
    }

    let found: boolean = false;

    this.authService.claims.forEach((claim) => {
      if (claim.endsWith(route)) {
        found = true;
      }

      if (!found) {
        const claimsSplit = claim.split('/');

        if (claimsSplit.length > 2) {
          const impliedClaim = claimsSplit[0] + '/' + claimsSplit[1];
          if (impliedClaim.endsWith(route)) {
            found = true;
          }
        }
      }
    });

    return found;
  }

  getDefaultPage(): string {
    return 'dashboard';
    // return "login";
  }
}
