import { Pipe, PipeTransform } from '@angular/core';
import { IPaymentRecord } from '../../interfaces/IPaymentRecord';
import { Card } from '@stripe/stripe-js';

interface StripeCard extends Card {
  wallet: { type: 'google_pay' | 'apple_pay' };
}

@Pipe({ name: 'paymentMethod' })
export class PaymentMethodPipe implements PipeTransform {
  private monthKeys = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  public constructor() {}

  transform(paymentMethod: IPaymentRecord, type: string = 'summary'): string {
    if (!paymentMethod) {
      return 'None';
    }

    console.log(`Transform ${paymentMethod.id} as ${type}`);

    if (paymentMethod.providerRecord.provider) {
      switch (paymentMethod.providerRecord.provider.toLowerCase()) {
        case 'stripe':
          return this.transformStripe(paymentMethod, type);

        case 'paypal':
          return this.transformPayPal(paymentMethod, type);

        case 'direct_payment':
          return this.transformDirectPayment(paymentMethod, type);
      }
    }
    return 'None';
  }

  transformBookingPaymentMethod(paymentMethod: any, type: string = 'summary'): string {
    if (!paymentMethod) {
      return '';
    }

    if (paymentMethod.provider) {
      switch (paymentMethod.provider.toLowerCase()) {
        case 'stripe':
          return this.transformStripeBookingPaymentMethod(paymentMethod, type);

        case 'paypal':
          return this.transformPayPal(paymentMethod, type);

        case 'direct_payment':
          return this.transformDirectPayment(paymentMethod, type);
      }
    }
    return '';
  }

  private transformDirectPayment(paymentMethod: IPaymentRecord, type: string): string {
    return 'Paid Directly';
  }

  private transformStripe(paymentMethod: IPaymentRecord, type: string): string {
    //
    // For legacy payment methods, the data property contains the card object, but for newer payment methods created
    // via payment/setup intents, the payment method data is nested inside a property by the same name as the type of
    // the payment method
    //
    const isLegacy = paymentMethod.providerRecord.data.object !== 'payment_method';
    const paymentMethodType = isLegacy
      ? paymentMethod.providerRecord.data.object
      : paymentMethod.providerRecord.data.type;
    const paymentMethodData = isLegacy
      ? paymentMethod.providerRecord.data
      : paymentMethod.providerRecord.data[paymentMethod.providerRecord.data.type];

    switch (paymentMethodType) {
      case 'card':
        return this.transformStripeCard(paymentMethodData, type);
      case 'paypal':
        let result = 'PayPal';
        if (paymentMethodData.payer_email) {
          result += ` (${paymentMethodData.payer_email})`;
        }

        return result;
    }
    return '';
  }

  private transformStripeBookingPaymentMethod(paymentMethod: any, type: string): string {
    if (paymentMethod.data.card) {
      return this.transformStripeCard(paymentMethod.data.card, type);
    } else if (paymentMethod.data && paymentMethod.data.brand) {
      return this.transformStripeCard(paymentMethod.data, type);
    }
    return '';
  }

  private transformStripeCard(cardInfo: StripeCard, type: string): string {
    let paymentType = 'Payment Card';

    if (cardInfo.wallet) {
      switch (cardInfo.wallet.type) {
        case 'apple_pay':
          paymentType = 'Apple Pay';
          break;

        case 'google_pay':
          paymentType = 'Google Pay';
          break;

        default:
          paymentType = cardInfo.wallet.type;
          break;
      }

      // Use summary for wallet payment methods for now
      type = 'summary';
    }

    switch (type) {
      case 'summary':
        const cardBrand = (cardInfo.brand && cardInfo.brand.toUpperCase()) || 'Unknown card';
        return `${paymentType} (${cardBrand} ending in ${cardInfo.last4})`;

      case 'number':
        return `XXXX  XXXX  XXXX  ${cardInfo.last4}`;

      case 'expiry':
        const validUntil = 'Valid Until';
        const month = this.monthKeys[cardInfo.exp_month - 1];
        return `${validUntil} ${month} ${cardInfo.exp_year.toString()}`;

      case 'expiryShort':
        const mm = +cardInfo.exp_month < 10 ? '0' + cardInfo.exp_month : cardInfo.exp_month;
        let yy = cardInfo.exp_year.toString();
        yy = yy.substr(yy.length - 2);
        return `${mm}/${yy}`;

      case 'brand':
        return `${cardInfo.brand.toUpperCase()}`;
    }
    return '';
  }

  private transformPayPal(paymentMethod: IPaymentRecord, type?: string): string {
    // TODO: Implement support for PayPal
    return 'Paypal';
  }
}
