import { Pipe, PipeTransform } from '@angular/core';

export const ANY_TEMPLATE = '__ANY_TEMPLATE__';
export const NO_TEMPLATE = '__NO_TEMPLATE__';

const TEMPLATE_FRIENDLY_NAMES = [
  [ANY_TEMPLATE, '<Any template>'],
  [NO_TEMPLATE, '<No template>'],
  ['template-deals.php', 'Deals'],
  ['template-frontpage-test.php', 'Frontpage Test'],
  ['template-location.php', 'Location'],
  ['template-narrow-content.php', 'Narrow Content'],
  ['template-sitemap.php', 'Sitemap'],
  ['template-variant-location.php', 'Location Variant'],
];

@Pipe({ name: 'webPageTemplate' })
export class WebPageTemplatePipe implements PipeTransform {
  private templateFriendlyNameMap: { [templateName: string]: string } = {};

  constructor() {
    // Build map of friendly template names
    for (const templateInfo of TEMPLATE_FRIENDLY_NAMES) {
      this.templateFriendlyNameMap[templateInfo[0]] = templateInfo[1];
    }
  }

  transform(template: string): string {
    if (template === '') {
      template = NO_TEMPLATE;
    }
    if (template === undefined) {
      template = ANY_TEMPLATE;
    }
    return this.templateFriendlyNameMap[template] || template;
  }
}
