import { Injectable } from '@angular/core';
const TOKEN_KEY = 'UI_TOKEN';


@Injectable()
export class StorageService {
  get isLocalStorageSupported(): boolean {
    return this.isStorageSupported(false);
  }

  get isSessionStorageSupport(): boolean {
    return this.isStorageSupported(true);
  }

  set token(value: string) {
    this.set(TOKEN_KEY, value);
  }
  get token(): string {
    return this.get(TOKEN_KEY);
  }

  set(key: string, value: string, session = false) {
    try {
      this.getStorage(session).setItem(key, value);
    } catch (e) {
      // Suppress errors in incognito mode
    }
  }

  get(key: string, session = false): string {
    try {
      return this.getStorage(session).getItem(key);
    } catch (e) {
      // Suppress errors in incognito mode
    }
    return null;
  }

  remove(key: string, session = false) {
    try {
      this.getStorage(session).removeItem(key);
    } catch (e) {
      // Suppress errors in incognito mode
    }
  }

  clear(session = false) {
    try {
      this.getStorage(session).clear();
    } catch (e) {
      // Suppress errors in incognito mode
    }
  }

  private isStorageSupported(session: boolean): boolean {
    try {
      this.getStorage(session).setItem('Hello', 'World');
    } catch (e) {
      return false;
    }
    return true;
  }

  private getStorage(session: boolean): Storage {
    return session ? sessionStorage : localStorage;
  }
}
