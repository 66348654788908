import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'price' })
export class PricePipe implements PipeTransform {
  transform(
    price: number,
    currency: string = 'eur',
    onlyCurrency: boolean = false,
    decimalPlaces: number = 2,
    hideCurrency: boolean = false
  ): string {
    // console.log(price, currency, onlyCurrency, decimalPlaces);

    let currencySymbol: string = '';
    let currencyString: string = '';

    if (!hideCurrency) {
      switch (currency.toLowerCase()) {
        case 'eur':
          currencySymbol = '€';
          break;

        case 'usd':
          currencySymbol = '$';
          break;

        case 'gbp':
          currencySymbol = '£';
          break;

        default:
          currencyString = ` ${currency.toUpperCase()}`;
          break;
      }
    }
    if (price) {
      if (price < 0) {
        price = price * -1;
        return `- ${currencySymbol}${price.toFixed(decimalPlaces)}${currencyString}`;
      } else {
        return `${currencySymbol}${price.toFixed(decimalPlaces)}${currencyString}`;
      }
    } else if (onlyCurrency) {
      return `${currencySymbol}${currencyString}`;
    } else {
      const zeroPrice = 0;
      return `${currencySymbol}${zeroPrice.toFixed(decimalPlaces)}${currencyString}`;
    }
  }
}
