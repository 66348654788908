import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable()
export class HttpService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  getHeaders() {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    });
    return headers;
  }

  getSecureHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${this.storage.token}`,
    });
    return headers;
  }

  private setUrl(url: string): string {
    return this.setUrlWithApi(url, environment.API);
  }

  private setUrlWithApi(url: string, api: string): string {
    if (url.startsWith('http')) {
      return url;
    }
    return api + url;
  }

  anonPostWithApi<T>(url: string, obj: any, api: string, forceJson: boolean = false) {
    const payload = obj ? JSON.stringify(obj) : null;
    const options = forceJson ? { headers: this.getHeaders() } : undefined;
    return this.http.post<T>(this.setUrlWithApi(url, api), payload, options).pipe(catchError(this.handleError));
  }

  get<T>(url: string): Observable<T> {
    const options = { headers: this.getSecureHeaders() };
    return this.http.get<T>(this.setUrl(url), options).pipe(catchError(this.handleError));
  }

  getWithApi<T>(url: string, api: string): Observable<T> {
    const options = { headers: this.getSecureHeaders() };
    return this.http.get<T>(this.setUrlWithApi(url, api), options).pipe(catchError(this.handleError));
  }

  postWithApi<T>(url: string, data: any, api: string, headers?: HttpHeaders): Observable<any> {
    const options = { headers: headers || this.getSecureHeaders() };
    const payload = data ? JSON.stringify(data) : null;
    return this.http.post<T>(this.setUrlWithApi(url, api), payload, options).pipe(catchError(this.handleError));
  }

  putWithApi<T>(url: string, data: any, api: string, headers?: HttpHeaders): Observable<any> {
    const options = { headers: headers || this.getSecureHeaders() };
    const payload = data ? JSON.stringify(data) : null;
    return this.http.put<T>(this.setUrlWithApi(url, api), payload, options).pipe(catchError(this.handleError));
  }

  deleteWithApi<T>(url: string, api: string): Observable<any> {
    const options = { headers: this.getSecureHeaders() };
    return this.http.delete<T>(this.setUrlWithApi(url, api), options).pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    return observableThrowError(error);
  }
}

// import { throwError as observableThrowError, Observable } from 'rxjs';

// import { Injectable } from '@angular/core';
// import { HttpClient } from "@angular/common/http";
// // import { JwtModule } from '@auth0/angular-jwt';
// import { TOKEN_KEY } from './user.service';
// import { LogoutService } from './logout.service';

// @Injectable()
// export class HttpService {
//     private contentHeaders: Headers;

//     constructor(private http: HttpClient,
//         private authHttp: HttpClient,
//         private logoutService: LogoutService) {
//     }

//     /**
//      * Appends header (replacing existing)
//      * @param key header key
//      * @param value header value
//      */
//     appendHeader(key: string, value: string): void {
//         // remove the header if it already exist
//         this.contentHeaders.delete(key);
//         // append the new header
//         this.contentHeaders.append(key, value);
//     }

//     /**
//      * Removes header
//      * @param key Header to remove
//      */
//     removeHeader(key: string): void {
//         this.contentHeaders.delete(key);
//     }

//     get<T>(url: string) {
//         url = this.setUrl(url);
//         console.log(url);
//         return this.mapAndCatch<T>(this.authHttp.get(url));
//     }

//     /**
//      * POST with JWT
//      * @param url endpoint URI
//      * @param obj body data
//      * @returns {Observable<R>} result
//      */
//     post<T>(url: string, obj: any) {
//         url = this.setUrl(url);
//         return this.mapAndCatch<T>(this.authHttp.post(this.setUrl(url), JSON.stringify(obj)));
//     }

//     /**
//      * PUT with JWT
//      * @param url endpoint URI
//      * @param obj body data
//      * @returns {Observable<R>} result
//      */
//     put<T>(url: string, obj: any) {
//         url = this.setUrl(url);
//         return this.mapAndCatch<T>(this.authHttp.put(this.setUrl(url), JSON.stringify(obj)));
//     }

//     /**
//      * DELETE with JWT
//      * @param url endpoint URI
//      * @returns {Observable<R>}
//      */
//     delete<T>(url: string) {
//         url = this.setUrl(url);
//         return this.mapAndCatch<T>(this.authHttp.delete(url));
//     }

//     /**
//      * GET without JWT
//      * @param url
//      * @param headers
//      * @returns {Observable<R>}
//      */
//     anonGet<T>(url: string, headers?: Headers) {
//         url = this.setUrl(url);
//         return this.mapAndCatch<T>(this.http.get(this.setUrl(url), { headers: headers }));
//     }

//     /**
//      * POST without JWT
//      * @param url
//      * @param obj
//      * @param headers
//      * @returns {Observable<R>}
//      */
//     anonPost<T>(url: string, obj: any, headers?: Headers) {
//         url = this.setUrl(url);
//         const observable = this.http.post(this.setUrl(url), JSON.stringify(obj), { headers: headers });
//         return this.mapAndCatch<T>(observable);
//     }

//     /**
//  * POST without JWT
//  * @param url
//  * @param obj
//  * @param headers
//  * @returns {Observable<R>}
//  */
//     anonPut<T>(url: string, obj: any, headers?: Headers) {
//         url = this.setUrl(url);
//         const observable = this.http.put(this.setUrl(url), JSON.stringify(obj), { headers: headers });
//         return this.mapAndCatch<T>(observable);
//     }

//     /**
//      * boilerplate for http response observable
//      */
//     private mapAndCatch<T>(observable: Observable<Response>, raw?: boolean) {
//         const o = raw ? observable : observable.pipe(map(this.parseJson));
//         return o.catch((err: any, caught: any) => this.handleErrorInternal(err));
//     }

//     /**
//      * JSON parser helper
//      * @param response ajax response
//      * @returns {any} parsed json or null if response body is empty
//      */
//     private parseJson<T>(response: any) {
//         if (('_body' in response) && !(response as any)._body) {
//             return null;
//         } else {
//             return response.json();
//         }
//     }

//     // TODO: refactor
//     private setUrl(url: string): string {
//         if (url.startsWith('http')) {
//             return url;
//         }
//         return API_URL + url;
//     }

//     /**
//      * Processes error response
//      */
//     private handleErrorInternal(response: Response | Error) {
//         if (response instanceof Response) {
//             const { status } = response;
//             // no access/token expired, logout the user
//             if (status === 401) {
//                 this.logoutService.logout();
//             }
//             const error = response && response.json() || (status >= 500 ? 'Server error' : 'Invalid request');

//             if (error instanceof ProgressEvent && error.type === 'error') {
//                 return observableThrowError('Error making CORS http request');
//             } else {
//                 return observableThrowError(error);
//             }
//         } else if (response instanceof Error) {
//             return observableThrowError(response);
//         }

//         return observableThrowError('Undefined error');
//     }
// }
