
export const environment = {
  production: false,
  ENV: "DEV",
  API: "https://luggagehero-admin-api-staging.herokuapp.com",
  APIv2: "https://admin-api-staging.luggagehero.com",
  POINTER_API: "https://pointer-api-staging.luggagehero.com",
  IDENTITY_API: "https://identity-api-staging.luggagehero.com",
  TRAVELLER_URL: "https://app-staging.luggagehero.com",
  STORAGE_MANAGER_URL: "https://storage-manager-staging.luggagehero.com",
  LUGGAGEHERO_SITE_URL : "https://lugdevelopment.wpengine.com"
};
