import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxUploaderModule } from 'ngx-uploader';
import { AppTranslationModule } from '../app.translation.module';
import { DateStringPipe } from '../core/pipes/dateString.pipe';
import { PricePipe } from '../core/pipes/price.pipe';
import { DatePickerPipe } from '../core/pipes/datePicker.pipe';
import {
  DateMomentPipe,
  DateYearMomentPipe,
  DateYearMomentPipeMonth,
  DateTimeYearMomentPipe,
} from '../core/pipes/dateMoment.pipe';
import { CapitalizePipe } from '../core/pipes/capitalize.pipe';
import { PaymentMethodPipe } from '../core/pipes/paymentmethod.pipe';
import { WebPageTemplatePipe } from '../core/pipes/webPageTemplate.pipe';
import { BookingCategoryPipe } from '../core/pipes/bookingCategory.pipe';
import { BaThemeConfig } from './theme.config';

import { BaThemeConfigProvider } from './theme.configProvider';

import {
  BaBackTop,
  BaCard,
  BaCheckbox,
  BaContentTop,
  BaMenuItem,
  BaMenu,
  BaMsgCenter,
  BaMultiCheckbox,
  BaPageTop,
  BaPictureUploader,
  BaSidebar,
} from './components';

import { BaCardBlur } from './components/baCard/baCardBlur.directive';

import { BaScrollPosition, BaSlimScroll, BaThemeRun } from './directives';

import { BaAppPicturePipe, BaKameleonPicturePipe, BaProfilePicturePipe } from './pipes';

import { BaImageLoaderService, BaMenuService, BaThemePreloader, BaThemeSpinner } from './services';

import { EmailValidator, EqualPasswordsValidator } from './validators';

const NGA_COMPONENTS = [
  BaBackTop,
  BaCard,
  BaCheckbox,
  BaContentTop,
  BaMenuItem,
  BaMenu,
  BaMsgCenter,
  BaMultiCheckbox,
  BaPageTop,
  BaPictureUploader,
  BaSidebar,
];

const NGA_DIRECTIVES = [BaScrollPosition, BaSlimScroll, BaThemeRun, BaCardBlur];

const NGA_PIPES = [
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe,
  DateStringPipe,
  PricePipe,
  PaymentMethodPipe,
  DateMomentPipe,
  DateYearMomentPipe,
  DateYearMomentPipeMonth,
  DateTimeYearMomentPipe,
  DatePickerPipe,
  CapitalizePipe,
  WebPageTemplatePipe,
  BookingCategoryPipe,
];

const NGA_SERVICES = [BaImageLoaderService, BaThemePreloader, BaThemeSpinner, BaMenuService];

const NGA_VALIDATORS = [EmailValidator, EqualPasswordsValidator];

@NgModule({
  declarations: [...NGA_PIPES, ...NGA_DIRECTIVES, ...NGA_COMPONENTS],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, AppTranslationModule, NgxUploaderModule],
  exports: [...NGA_PIPES, ...NGA_DIRECTIVES, ...NGA_COMPONENTS],
})
export class NgaModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: NgaModule,
      providers: [BaThemeConfigProvider, BaThemeConfig, ...NGA_VALIDATORS, ...NGA_SERVICES],
    };
  }
}
