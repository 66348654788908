import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateTimeMoment' })
export class DateMomentPipe implements PipeTransform {
  transform(inputDate: Date, dateFormat: string = 'DD-MMM HH:mm'): string {
    return moment(inputDate).format(dateFormat);
  }
}

@Pipe({ name: 'dateYearMoment' })
export class DateYearMomentPipe implements PipeTransform {
  transform(inputDate: Date, dateFormat: string = 'DD-MM-YYYY'): string {
    return moment(inputDate).format(dateFormat);
  }
}

@Pipe({ name: 'dateYearMomentMonth' })
export class DateYearMomentPipeMonth implements PipeTransform {
  transform(inputDate: Date, dateFormat: string = 'DD-MMM-YYYY'): string {
    return moment(inputDate).format(dateFormat);
  }
}

@Pipe({ name: 'dateYearTimeMoment' })
export class DateTimeYearMomentPipe implements PipeTransform {
  transform(inputDate: Date, dateFormat: string = 'DD-MM-YYYY HH:mm'): string {
    return moment(inputDate).format(dateFormat);
  }
}
