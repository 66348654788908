import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

export interface ILoginResponse {
  jwt?: string;
  success?: boolean;
  message?: string;
  role?: string;
  claims?: string[];
  userId?: string;
  email?: string;
  user?: string;
}

@Injectable()
export class LoginService {
  baseUrl: string;
  identityApiUrl: string;

  constructor(private httpService: HttpService, private authService: AuthService, private router: Router) {
    this.baseUrl = environment.APIv2;
    this.identityApiUrl = environment.IDENTITY_API;
  }

  /**
   * Logs in
   * @param username User Name
   * @param password Password
   * @returns {Observable<string>} JWT token
   */
  login(username: string, password: string): Observable<string> {
    const credentials = {
      email: username,
      password: password,
    };
    return this.httpService
      .anonPostWithApi(`${this.identityApiUrl}/users/authenticate/admin`, credentials, environment.APIv2, true)
      .pipe(
        catchError((err) => {
          // TODO: Update this check when server returns 401 as it should
          if (err.status === 400 && err.statusText.match(/bad request/i)) {
            return observableThrowError(`Wrong email/password`);
          }
          return observableThrowError(err.message);
        }),
        map((res: ILoginResponse) => {
          this.authService.clear();
          this.authService.token = res.jwt;
          this.authService.role = res.role;
          this.authService.claims = res.claims;
          this.authService.email = res.email;
          this.authService.userId = res.userId;
          this.authService.user = res.user;
          return res.jwt;
        })
      );
  }

  logout() {
    this.authService.clear();
    this.router.navigate(['login']);
  }
}
