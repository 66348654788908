import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
  transform(value: any): string {
    if (value) {
      let valueString = value.toString();

      if (valueString.length > 0) {
        return valueString.charAt(0).toUpperCase() + valueString.slice(1);
      }

      return value;
    }
    return '';
  }
}
