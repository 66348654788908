import { Pipe, PipeTransform } from '@angular/core';
/**
 * Make a string readable without converting to local date timezone
 */

@Pipe({ name: 'dateString' })
export class DateStringPipe implements PipeTransform {
    transform(input: string): string {
        const prettyArr = input.split('T');
        if (prettyArr.length === 0) {
            return input;
        }
        const pretty = `${prettyArr[0]} ${prettyArr[1].slice(0, 5)}`;
        return pretty;
    }
}
