import { NgModule } from "@angular/core";
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { TOKEN_KEY } from "./services/auth.service";

export function jwtOptionsFactory(tokenService) {
  return {
    tokenGetter: () => {
      const token = localStorage.getItem(TOKEN_KEY);
    }
  };
}

@NgModule({
  // ...
  imports: [
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: []
      }
    })
  ],
  providers: []
})
export class AuthModule {}

/*

import { Http, RequestOptions } from '@angular/http';
import { AuthHttp, AuthConfig,JwtHelper } from 'angular2-jwt';
import { TOKEN_KEY } from './services/user.service';

function authHttpServiceFactory(http: Http, options: RequestOptions) {
  return new AuthHttp(new AuthConfig({
      tokenName: TOKEN_KEY,
      tokenGetter: (() => {
          const token = localStorage.getItem(TOKEN_KEY);
          return localStorage.getItem(TOKEN_KEY);
      }),
      globalHeaders: [
          { 'Content-Type': 'application/json' }],
      noJwtError: true,
  }), http, options);
}

@NgModule({
  providers: [JwtHelper,
      {
          provide: AuthHttp,
          useFactory: authHttpServiceFactory,
          deps: [Http, RequestOptions]
      }
  ]
})
export class AuthModule { }
*/
