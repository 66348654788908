import { Component } from '@angular/core';

import { BaMenuService, layoutSizes } from '../theme';
import { PAGES_MENU } from './pages.menu';

import { version } from '../../../package.json';

@Component({
  selector: 'pages',
  template: `
    <ba-sidebar></ba-sidebar>
    <ba-page-top></ba-page-top>
    <div class="al-main" (click)="onPageClick()">
      <div class="al-content">
        <ba-content-top></ba-content-top>
        <router-outlet></router-outlet>
      </div>
    </div>
    <footer class="al-footer clearfix">
      <div class="al-footer-right">Version {{ appVersion }}</div>
      <div class="al-footer-main clearfix">
        <div class="al-copy">
          Copyright &copy; 2019
          <a href="https://luggagehero.com" target="_blank">LuggageHero</a>
        </div>
      </div>
    </footer>
    <ba-back-top position="200"></ba-back-top>
  `,
})
export class Pages {
  public appVersion = version;

  constructor(private _menuService: BaMenuService) {}

  ngOnInit() {
    this._menuService.updateMenuByRoutes(PAGES_MENU);
  }

  onPageClick() {
    if (window.innerWidth <= layoutSizes.resWidthCollapseSidebar) {
      this._menuService.hideMenu();
    }
  }
}
