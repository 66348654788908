import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { routing } from "./pages.routing";
import { NgaModule } from "../theme/nga.module";
// import { AppToastOptions, ToastOptions, ToastModule } from '../core/services/toast.service';
import { Pages } from "./pages.component";
// import { DateStringPipe } from '../core/pipes/dateString.pipe';

@NgModule({
  imports: [
    CommonModule,
    NgaModule,
    routing
    // , ToastModule.forRoot()
  ],
  declarations: [Pages],
  providers: [
    // { provide: ToastOptions, useClass: AppToastOptions }
  ]
})
export class PagesModule {}
