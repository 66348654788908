// angular
import { Injectable } from "@angular/core";

// libs
import {
  ToastrService,
  Toast,
  ToastrIconClasses,
  GlobalConfig
} from "ngx-toastr";

// Toastr options
const TOASTR_ICON_CLASSES: ToastrIconClasses = {
  error: "toast-error",
  info: "toast-info",
  success: "toast-success",
  warning: "toast-warning"
};
const BANNER_TOASTR_CONFIG: GlobalConfig = {
  newestOnTop: true,
  maxOpened: 1,
  autoDismiss: true,
  iconClasses: TOASTR_ICON_CLASSES,
  preventDuplicates: true,
  disableTimeOut: false,
  timeOut: 8000,
  closeButton: true,
  extendedTimeOut: 1000,
  progressBar: false,
  enableHtml: true,
  toastClass: "toast-banner",
  positionClass: "toast-top-center",
  titleClass: "toast-title-banner",
  messageClass: "toast-message-banner",
  easing: "fade",
  easeTime: 50,
  tapToDismiss: true,
  toastComponent: Toast,
  onActivateTick: false,
  countDuplicates: false,
  progressAnimation: "decreasing",
  resetTimeoutOnDuplicate: true
};
const CHAT_TOASTR_CONFIG: GlobalConfig = {
  newestOnTop: true,
  maxOpened: 1,
  autoDismiss: true,
  iconClasses: TOASTR_ICON_CLASSES,
  preventDuplicates: true,
  disableTimeOut: false,
  timeOut: 10000,
  closeButton: true,
  extendedTimeOut: 1000,
  progressBar: false,
  enableHtml: true,
  toastClass: "toast-chat",
  positionClass: "toast-bottom-right",
  titleClass: "toast-title-chat",
  messageClass: "toast-message-chat",
  easing: "fade",
  easeTime: 50,
  tapToDismiss: true,
  toastComponent: Toast,
  onActivateTick: false,
  countDuplicates: false,
  progressAnimation: "decreasing",
  resetTimeoutOnDuplicate: true
};

@Injectable()
export class ToastService {
  constructor(private toastr: ToastrService) {}

  showSuccess(title: string, text: string) {
    this.toastr.success(text, title, CHAT_TOASTR_CONFIG);
  }

  showError(title: string, text: string) {
    this.toastr.error(this.handlerErrorText(text), title, CHAT_TOASTR_CONFIG);
  }

  showWarning(title: string, text: string) {
    this.toastr.warning(text, title, CHAT_TOASTR_CONFIG);
  }

  showInfo(title: string, text: string) {
    this.toastr.info(text, title, CHAT_TOASTR_CONFIG);
  }

  private handlerErrorText(text: string) {
    if (!text) {
      text = "";
    }
    return text;
  }
}
