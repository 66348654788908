import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";
import { IDatePicker } from '../utils';

@Pipe({ name: 'datePicker' })
export class DatePickerPipe implements PipeTransform {

  transform(inputDate: IDatePicker): string {
    return `${inputDate.day}/${inputDate.month}/${inputDate.year}`;
  }
}
