import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from './storage.service';

const USER_KEY = 'user';
const ROLES_KEY = 'roles';
const CLAIMS_KEY = 'claims';
const USERID_KEY = 'userId';
const EMAIL_KEY = 'email';

export const TOKEN_KEY = 'UI_TOKEN';

@Injectable()
export class AuthService {
  private jwtHelper = new JwtHelperService();

  constructor(private storageService: StorageService) {}

  set user(user: string) {
    this.storageService.set(USER_KEY, user);
  }
  get user() {
    return this.storageService.get(USER_KEY);
  }

  set token(token: string) {
    this.storageService.set(TOKEN_KEY, token);
  }
  get token() {
    return this.storageService.get(TOKEN_KEY);
  }

  set role(role: string) {
    this.storageService.set(ROLES_KEY, role);
  }
  get role() {
    return this.storageService.get(ROLES_KEY);
  }

  set email(email: string) {
    this.storageService.set(EMAIL_KEY, email);
  }
  get email() {
    return this.storageService.get(EMAIL_KEY);
  }

  set userId(userId: string) {
    this.storageService.set(USERID_KEY, userId);
  }
  get userId() {
    return this.storageService.get(USERID_KEY);
  }

  set claims(claims: string[]) {
    if (claims) {
      this.storageService.set(CLAIMS_KEY, claims.join(','));
    }
  }
  get claims() {
    const claimsString = this.storageService.get(CLAIMS_KEY);
    return claimsString ? claimsString.split(',') : [];
  }

  get isLoggedIn() {
    return this.token && !this.jwtHelper.isTokenExpired(this.token);
  }

  clear() {
    this.storageService.remove(TOKEN_KEY);
    this.storageService.remove(USER_KEY);
    this.storageService.remove(ROLES_KEY);
    this.storageService.remove(CLAIMS_KEY);
    this.storageService.remove(EMAIL_KEY);
    this.storageService.remove(USERID_KEY);
  }
}
