import { Pipe, PipeTransform } from '@angular/core';
import { IBooking } from '../../interfaces/ibooking';

@Pipe({ name: 'bookingCategory' })
export class BookingCategoryPipe implements PipeTransform {
  transform(value: string | IBooking): string {
    const category = typeof value === 'string' ? value : value.metadata ? value.metadata.lh_booking_category : '';

    switch (category) {
      case 'advance':
        return 'Advance';

      case 'same_day':
        return 'Same-day';

      case 'walk_in':
        return 'Walk-in';

      case 'guest':
        return 'Guest';

      default:
        return '';
    }
  }
}
